import { Individual, makeIndividual } from '@/types/people'

export enum ContactSource {
  Activities = 0,
  YouthHomes = 1,
  Adhesions = 2,
}

export class Contact {
  constructor(
    public individual: Individual,
    public emails: string[],
    public phones: string[]
  ) {
  }
}

export function makeContact(jsonData: any = null): Contact {
  if (!jsonData) {
    jsonData = {}
  }
  return new Contact(
    makeIndividual(jsonData.individual),
    jsonData.emails || [],
    jsonData.phones || []
  )
}
