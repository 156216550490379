<template>
  <div class="search" v-if="hasViewPerm">
    <b-form @submit="onSearch">
      <b-form-group
        id="search-group"
        label-for="entities-search"
        :description="description"
      >
        <b-form-row>
          <b-col cols="12">
            <div class="include-members-checkbox text-right" v-if="isFamily">
              <b-form-checkbox
               id="isMain"
               v-model="otherMembers"
               :unchecked-value="false"
               :value="true"
              >
                Inclure les intervenants
              </b-form-checkbox>
            </div>
            <b-input-group prepend="Recherche" class="mt-3 input-group-primary">
              <b-form-input
                type="search"
                :placeholder=placeholder
                v-model="searchText"
                autocomplete="off"
                :class="{ filled: searchText }"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-form-row>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeEntity } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'SearchEntity',
  components: {},

  mixins: [BackendMixin],
  props: {
    isFamily: Boolean,
  },
  data() {
    return {
      searchText: '',
      otherMembers: false,
    }
  },
  watch: {
    entitySearch: function(newValue, oldValue) {
      this.searchText = newValue
    },
  },
  mounted() {
    this.searchText = this.entitySearch
  },
  computed: {
    description() {
      if (this.isFamily) {
        return 'Recherchez une famille à partir de son nom, de son identifiant, d\'un email ou d\'un numéro de téléphone'
      } else {
        return 'Recherchez une structure à partir de son nom, de son identifiant, d\'un email ou d\'un numéro de téléphone'
      }
    },
    placeholder() {
      if (this.isFamily) {
        return 'Entrez le nom d\'une famille'
      } else {
        return 'Entrez le nom d\'une structure'
      }
    },
    entitySearch() {
      if (this.isFamily) {
        return store.getters.familySearch
      } else {
        return store.getters.entitySearch
      }
    },
    hasViewPerm() {
      if (this.isFamily) {
        return this.hasPerm('families.view_familyentity')
      } else {
        return this.hasPerm('people.view_contact')
      }
    },
  },
  methods: {
    ...mapActions([
      'addError', 'addSuccess'
    ]),
    ...mapMutations([
      'setEntitySearch', 'setFamilySearch', 'setSearchedEntities', 'setSearchedFamilies', 'startLoading', 'endLoading'
    ]),
    onSearch(evt) {
      evt.preventDefault()
      this.searchEntities(this.searchText)
    },
    async searchEntities(text) {
      if (text) {
        const loadingName = 'searchEntities'
        this.startLoading(loadingName)
        try {
          let url = '/api/people/contacts/search/?text=' + this.searchText
          if (this.isFamily) {
            const includeOthers = this.otherMembers ? 1 : 0
            url = '/api/families/families/search/?text=' + this.searchText + '&other=' + includeOthers
          }
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          let entities = await resp.data.map(elt => makeEntity(elt))
          if (this.isFamily) {
            this.setFamilySearch(text)
            this.setSearchedFamilies(entities)
          } else {
            this.setEntitySearch(text)
            this.setSearchedEntities(entities)
          }
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(loadingName)
      } else {
        // if (this.isFamily) {
        //   this.setSearchedFamilies([])
        // } else {
        //   this.setSearchedEntities([])
        // }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.include-members-checkbox {
  margin-bottom: -18px;
  font-size: 16px;
}
.form-group label {
  font-weight: normal !important;
}
</style>
