<template>
  <div v-if="hasPerm('people.view_tag')">
    <div class="tags-list">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <li v-if="!isLoading(loadingName)">
        <a href @click.prevent="newTag()">
          <i class="fa fa-list"></i> Créer une liste de personnes
        </a>
      </li>
      <li class="tag-item" v-for="tag in tags" :key="tag.id">
        <a href @click.prevent="viewTag(tag)">
          <i class="fa fa-list-alt"></i>
          {{ tag.name }}
        </a>
      </li>
      <new-tag-modal :tags="tags" @done="loadTags()"></new-tag-modal>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'
import { makeTag } from '@/types/people'
import { BackendApi } from '@/utils/http'
import NewTagModal from '@/components/Tags/NewTagModal'

export default {
  name: 'tags-list',
  components: {
    NewTagModal,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    applyTo: String,
  },
  data() {
    return {
      loadingName: 'tagsList',
      tags: [],
    }
  },
  created() {
    this.loadTags()
  },
  watch: {
    applyTo: function() {
      this.loadTags()
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadTags() {
      this.tags = []
      if (this.hasPerm('people.view_tag')) {
        const url = '/api/people/tags/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.tags = resp.data.map(elt => makeTag(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    viewTag(tag) {
      router.push({ name: 'tag-detail', params: { id: tag.id, }, })
    },
    newTag() {
      this.$bvModal.show('bv-modal-new-tag')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
