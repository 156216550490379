<template>
  <div class="entities" v-if="canView">
    <page-header
      :title="isFamily ? 'Familles' : 'Annuaire'"
      :icon="isFamily ? 'fas fa-people-roof' : 'fa fa-address-book'"
    >
    </page-header>
    <b-row>
      <b-col cols="8">
        <div v-if="entities">
           <ul class="app-menu">
             <li class="first-row">
               <b-row>
                 <b-col>
                   <search-entity :is-family="isFamily"></search-entity>
                 </b-col>
                 <b-col cols="1" class="text-right">
                   <div class="first-row-btn-holder" v-if="isFamily">
                     <a href @click.prevent="showAddFamily" title="Ajout d'une famille" v-b-tooltip>
                      <i class="fa fa-person-circle-plus icon-button-big"></i>
                     </a>
                   </div>
                   <div class="first-row-btn-holder" v-else>
                     <router-link :to="{ name: 'new-contact', }" title="Ajout d'un contact" v-b-tooltip>
                       <i class="fa fa-person-circle-plus icon-button-big"></i>
                     </router-link>
                   </div>
                 </b-col>
                 <b-col cols="1" class="text-right">
                   <div class="first-row-btn-holder">
                     <a href @click.prevent="showSearch" title="Recherche avancée" v-b-tooltip>
                      <i class="fa fa-search icon-button-big"></i>
                     </a>
                   </div>
                 </b-col>
               </b-row>
             </li>
             <li>
               <loading-gif :loading-name="entitiesCountLoading"></loading-gif>
               <div v-if="!isLoading(entitiesCountLoading)">
                 <b-row>
                   <b-col v-if="showYearEntities === 0">
                     <a class="btn-show-entities" href @click.prevent="onShowAllEntities">
                       <i :class="isFamily ? 'fa-solid fa-people-roof' : 'fa fa-address-book'"></i>
                       {{ entityTypesLabel(entitiesCount) }}
                     </a>
                   </b-col>
                   <b-col
                     v-if="isFamily && !allVisible"
                     :class="(showYearEntities === 0) ? 'text-right' : ''"
                   >
                     <div v-for="year in years" :key="year.id">
                       <div v-if="year.isIndividual()">
                         <a
                           href
                           @click.prevent="onShowYearsMembers(year)"
                           v-b-tooltip="year.individualsCount + counterIndividuals(year.individualsCount)"
                           v-if="(showYearEntities === 0) || (year.id === showYearEntities)"
                         >
                           <i class="fas fa-user-friends"></i>
                           <counter-label
                             :counter="year.entitiesCount"
                             label="famille"
                             label-n="familles"
                           >
                           </counter-label>
                           en {{ year.name }}
                         </a>
                       </div>
                       <div v-else>
                         <a
                           href
                           @click.prevent="onShowYearsMembers(year)"
                           v-if="(showYearEntities === 0) || (year.id === showYearEntities)"
                         >
                           <i class="fas fa-user-friends"></i>
                           <counter-label
                             :counter="year.entitiesCount"
                             label="famille"
                             label-n="familles"
                           >
                           </counter-label>
                           en {{year.name}}
                         </a>
                       </div>
                     </div>
                   </b-col>
                   <b-col cols="4" v-if="allVisible || showYearEntities">
                     <div class="text-right">
                       <a class="btn btn-xs btn-refresh btn-secondary" href @click.prevent="onShowMenu">
                        <i class="fa fa-chevron-left"></i> Retour
                      </a>
                     </div>
                   </b-col>
                 </b-row>
               </div>
             </li>
             <li v-if="search && !isLoading(entitiesLoading)">
               <loading-gif :loading-name="searchLoading"></loading-gif>
               <div v-if="!isLoading(searchLoading)">
                 <b-row>
                   <b-col cols="8">
                     <i class="fa fa-search"></i> Recherche : {{ entityTypesLabel(searchCount) }}
                   </b-col>
                   <b-col cols="4">
                     <div class="text-right">
                       <a class="btn btn-xs btn-refresh btn-secondary" href @click.prevent="onBack">
                        <i class="fa fa-chevron-left"></i> Retour
                      </a>
                     </div>
                   </b-col>
                 </b-row>
               </div>
             </li>
             <li v-if="showMenu">
               <router-link :to="{ name: 'contact-members', }">
                 <i class="fa fa-envelope"></i> Contacter les membres
               </router-link>
             </li>
             <li v-if="isFamily && showMenu && canAdd">
               <a class="btn-add-entity" href @click.prevent="onAddEntity">
                 <i class="fa fa-plus"></i> Ajouter {{ entityTypeLabel() }}
               </a>
             </li>
             <li v-if="!isFamily && hasPerm('people.add_contact')">
               <router-link :to="{ name: 'new-contact', }">
                 <i class="fa fa-plus"></i> Ajouter un contact
               </router-link>
             </li>
             <li v-if="!isFamily">
               <router-link :to="{ name: 'single-individuals', }">
                 <i class="fa fa-th-list"></i> Liste des individus hors famille et structures
               </router-link>
             </li>
             <li v-if="!isFamily">
               <router-link :to="{ name: 'newsletter-individuals', }">
                 <i class="fa fa-newspaper"></i> Inscrits à la newsletter
               </router-link>
             </li>
             <li v-if="!isFamily && hasPerm('activities.view_activitymanager')">
               <router-link :to="{ name: 'activity-managers', }">
                 <i class="fa fa-people-line"></i> Animateurs
               </router-link>
             </li>
             <li v-if="showMenu && canViewChildren">
               <router-link :to="{ name: 'children-with-custom-age-groups', }">
                 <i class="fa-solid fa-people-group"></i> Groupes d'âge personnalisés
               </router-link>
             </li>
             <pages-list :apply-to="isFamily ? 'families' : 'entities'" v-if="showMenu"></pages-list>
             <tags-list v-if="!isFamily && showMenu"></tags-list>
           </ul>
        </div>
        <loading-gif :loading-name="entitiesLoading"></loading-gif>
        <div v-if="!isLoading(entitiesLoading)">
          <b-row v-for="entity in displayedEntities" :key="entity.id" class="row-line">
            <b-col :cols="isFamily ? 4 : 3">
              <router-link :to="getLinkToEntity(entity)">
                <span class="family-numbers">{{entity.id}}</span> {{ entity.name }}
              </router-link>
            </b-col>
            <b-col cols="2" v-if="!isFamily">
              <phone-link :phone="entity.phone"></phone-link>
            </b-col>
            <b-col :cols="isFamily ? 6 : 5">{{ entity.members|defaultValue('-') }}</b-col>
            <b-col cols="2">{{ entity.city.name }}</b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="recent-items text-right">
          <h3>Accès récent</h3>
          <loading-gif :loading-name="recentLoading"></loading-gif>
          <div v-if="!isLoading(recentLoading)">
            <p v-if="recentEntities.length === 0">-</p>
            <div v-for="entity in recentEntities" v-bind:key="entity.id" class="recent-line">
              <router-link :to="getLinkToEntity(entity)">
                <span class="family-numbers">{{entity.id}}</span> {{ entity.name }}
                <div class="small2">{{ entity.members }}</div>
              </router-link>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import router from '@/router'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import PagesList from '@/components/Pages/PagesList'
import TagsList from '@/components/Tags/TagsList'
import SearchEntity from '@/components/Entity/SearchEntity'
import CounterLabel from '@/components/Controls/CounterLabel'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import { BackendMixin } from '@/mixins/backend'
import { ContactSource } from '@/types/contacts'
import { makeEntity } from '@/types/people'
import { BackendApi } from '@/utils/http'
import { makeEntitiesAdhesionSummary } from '@/types/adhesions'

export default {
  name: 'entities',
  mixins: [BackendMixin],
  components: {
    PhoneLink,
    CounterLabel,
    LoadingGif,
    PageHeader,
    PagesList,
    SearchEntity,
    TagsList,
  },
  props: {
    isFamily: Boolean,
  },
  data() {
    return {
      recentEntities: [],
      entitiesCount: -1,
      entitiesLoading: 'entities',
      searchLoading: 'searchEntities',
      entitiesCountLoading: 'entitiesCount',
      recentLoading: 'recent-entities',
      years: [],
      showYearEntities: 0,
    }
  },
  watch: {
    entities: function(newValue, oldValue) {},
    isFamily: function() {
      this.onLoaded()
    },
  },
  computed: {
    sourceId() {
      return +this.source
    },
    isAdhesions() {
      return this.sourceId === ContactSource.Adhesions
    },
    search() {
      if (this.isFamily) {
        return this.familySearch
      } else {
        return this.entitySearch
      }
    },
    searchCount() {
      if (this.isFamily) {
        return this.searchedFamilies.length
      } else {
        return this.searchedEntities.length
      }
    },
    familySearch() {
      return store.getters.familySearch
    },
    families() {
      return store.getters.families
    },
    searchedFamilies() {
      return store.getters.searchedFamilies
    },
    entitySearch() {
      return store.getters.entitySearch
    },
    entities() {
      return store.getters.entities
    },
    searchedEntities() {
      return store.getters.searchedEntities
    },
    showAllFamilies() {
      return store.getters.showAllFamilies
    },
    showAllEntities() {
      return store.getters.showAllEntities
    },
    allVisible() {
      if (this.isFamily) {
        return this.showAllFamilies
      } else {
        return this.showAllEntities
      }
    },
    displayedEntities() {
      if (this.isFamily) {
        if (this.familySearch) {
          return this.searchedFamilies
        }
        if (this.showAllFamilies || (this.showYearEntities !== 0)) {
          return this.families
        }
      } else {
        if (this.entitySearch) {
          return this.searchedEntities
        }
        if (this.showAllEntities) {
          return this.entities
        }
      }
      return []
    },
    showMenu() {
      if (this.isLoading(this.entitiesLoading)) {
        return false
      }
      if (this.isFamily) {
        return (
          !this.familySearch && !this.showAllFamilies && (this.showYearEntities === 0)
        )
      } else {
        return (
          !this.entitySearch && !this.showAllEntities
        )
      }
    },
    canView() {
      if (this.isFamily) {
        return this.hasPerm('families.view_familyentity')
      } else {
        return this.hasPerm('people.view_contact')
      }
    },
    contactsSource() {
      return '' + ContactSource.Adhesions
    },
    canAdd() {
      if (this.isFamily) {
        return this.hasPerm('families.add_familyentity')
      } else {
        return this.hasPerm('people.add_contact')
      }
    },
    canViewChildren() {
      if (this.isFamily) {
        return this.hasPerm('families.view_child')
      }
      return false
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations([
      'startLoading', 'endLoading', 'setEntitySearch', 'setEntities',
      'setFamilies', 'setFamilySearch', 'setShowAllEntities', 'setShowAllFamilies'
    ]),
    counterIndividuals(nbIndividual) {
      let label = ''
      if (nbIndividual <= 1) {
        label = ' individu'
      } else {
        label = ' individus'
      }
      return label
    },
    showSearch() {
      store.commit('showSearch', true)
    },
    showAddFamily() {
      store.commit('showAddFamily', true)
    },
    onLoaded() {
      this.loadEntitiesCount()
      this.loadMembersAdhesionsCount()
      this.loadRecentEntities()
      if (!this.entitySearch) {
        if (this.isFamily) {
          this.setFamilies([])
        } else {
          this.setEntities([])
        }
        this.showAll(false)
        this.showYearEntities = 0
      }
    },
    onAddEntity() {
      if (this.isFamily) {
        router.push({ name: 'families-detail', params: { entityId: 'ajout', }, })
      } else {
        router.push({ name: 'entities-detail', params: { entityId: 'ajout', }, })
      }
    },
    onBack() {
      this.showAll(false)
      this.showYearEntities = 0
      if (this.isFamily) {
        this.setFamilySearch('')
      } else {
        this.setEntitySearch('')
      }
    },
    onShowMenu() {
      this.showAll(false)
      this.showYearEntities = 0
    },
    async onShowAllEntities() {
      this.showAll(true)
      this.showYearEntities = 0
      await this.loadAllEntities()
    },
    async onShowYearsMembers(year) {
      if (this.isFamily) {
        this.showAll(false)
        this.showYearEntities = year ? year.id : 0
        await this.loadYearMembers(year)
      }
    },
    getLinkToEntity(entity) {
      if (this.isFamily) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
    },
    async loadEntitiesCount() {
      this.startLoading(this.entitiesCountLoading)
      let url = '/api/people/contacts/count/'
      if (this.isFamily) {
        url = '/api/families/families/count/'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.entitiesCount = resp.data.count || 0
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.entitiesCountLoading)
    },
    async loadMembersAdhesionsCount() {
      this.startLoading(this.entitiesCountLoading)
      let url = '/api/adhesions/members/count/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.years = resp.data.years.map(makeEntitiesAdhesionSummary)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.entitiesCountLoading)
    },
    async loadYearMembers(year) {
      if (this.hasPerm('adhesions.view_adhesion') && year) {
        this.startLoading(this.entitiesLoading)
        const flag = year.isCivilYear ? 1 : 0
        const url = '/api/adhesions/members/list/' + flag + '/' + year.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          let entities = resp.data.map(makeEntity)
          if (this.isFamily) {
            this.setFamilies(entities)
          } else {
            this.setEntities(entities)
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.entitiesLoading)
      }
    },
    async loadAllEntities() {
      this.startLoading(this.entitiesLoading)
      let url = '/api/people/contacts/'
      if (this.isFamily) {
        url = '/api/families/families/'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        let entities = resp.data.map(elt => makeEntity(elt))
        if (this.isFamily) {
          this.setFamilies(entities)
        } else {
          this.setEntities(entities)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.entitiesLoading)
    },
    async loadRecentEntities() {
      this.startLoading(this.recentLoading)
      let url = '/api/people/contacts/recently_accessed/'
      if (this.isFamily) {
        url = '/api/families/families/recently_accessed/'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.recentEntities = resp.data.map(elt => makeEntity(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.recentLoading)
    },
    entityTypesLabel(value) {
      if (value >= 0) {
        let text = '' + value + ' '
        if (this.isFamily) {
          text += (value > 1 ? 'familles' : 'famille')
        } else {
          text += (value > 1 ? 'structures' : 'structure')
        }
        return text
      }
      return ''
    },
    entityTypeLabel() {
      if (this.isFamily) {
        return 'une famille'
      } else {
        return 'une structure'
      }
    },
    showAll(value) {
      if (this.isFamily) {
        this.setShowAllFamilies(value)
      } else {
        this.setShowAllEntities(value)
      }
    },
  },
}
</script>

<style scoped lang="less">
.first-row-btn-holder {
  padding-top: 15px;
}
</style>
