<template>
  <div>
    <div class="pages-list">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <li class="page-item" v-for="page in pages" v-bind:key="page.id">
        <a href @click="viewPage($event, page)">
          <i v-if="page.icon" :class="page.icon"></i>
          <i v-else class="fa fa-chevron-right"></i>
          {{ page.name }}
        </a>
      </li>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mapMutations, mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { makePage } from '@/types/pages'

export default {
  name: 'pages-list',
  components: {
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    applyTo: String,
  },
  data() {
    return {
      loadingName: 'pagesList',
      pages: [],
    }
  },
  created() {
    this.loadPages()
  },
  watch: {
    applyTo: function() {
      this.loadPages()
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadPages() {
      this.pages = []
      if (this.hasPerm('pages.view_page')) {
        this.startLoading(this.loadingName)
        const backendApi = new BackendApi('get', '/api/pages-list/' + this.applyTo + '/')
        try {
          let resp = await backendApi.callApi()
          this.pages = resp.data.map(elt => makePage(elt))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    viewPage(event, page) {
      event.preventDefault()
      event.stopPropagation()
      router.push({ name: 'page-detail', params: { slug: page.slug, }, })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
