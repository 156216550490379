<template>
  <span class="new-tag-modal" v-if="hasPerm('people.add_tag')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave()"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Créer"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Nouvelle liste</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            label="Sélectionnez le nom d'une nouvelle liste" label-for="nameTag"
          >
            <vue-bootstrap-typeahead
              :data="tagNames"
              ref="nameTagTypeAhead"
              v-model="tagName"
            ></vue-bootstrap-typeahead>
          </b-form-group>
          <div class="error" v-if="alreadyExists">
            <b>Ce nom existe déjà</b>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'NewTagModal',
  mixins: [BackendMixin],
  data() {
    return {
      tagName: '',
      errorText: '',
      tagId: null,
    }
  },
  props: {
    modalId: {
      type: String,
      default: 'bv-modal-new-tag',
    },
    tags: Array,
  },
  watch: {
    tags: function() {},
  },
  computed: {
    formInvalid() {
      return (this.tagName === '') || this.alreadyExists
    },
    tagNames() {
      return this.tags.map(elt => elt.name)
    },
    alreadyExists() {
      return this.tagNames.indexOf(this.tagName) >= 0
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onSave() {
      if (!this.formInvalid) {
        let url = '/api/people/duplicate-list-tag/'
        const backendApi = new BackendApi('post', url)
        const data = {
          name: this.tagName,
          individuals: [],
        }
        try {
          const resp = await backendApi.callApi(data)
          await this.addSuccess(resp.data.message)
          this.tagId = resp.data.tag_id
          this.tagName = ''
          this.$bvModal.hide(this.modalId)
          this.$emit('done', this.tagId)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style scoped>
.error {
  background: #eee;
  color: #cc0000;
  padding: 4px 10px;
}
.warning {
  background: #eee;
  color: #222;
  padding: 4px 10px;
}
</style>
